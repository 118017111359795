import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Comments from "../components/Comments"
import { CloudinaryMediaSingle } from "../components/CloudinaryMedia"
import GoogleMap from "../components/GoogleMap"
import { capitalize, urlToSecure } from "../helpers/common"
import RelatedItems from "../components/RelatedItems"
// @material-ui/core components
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { createStyles } from "@material-ui/core"
// Material UI Kit Components
import GridContainer from "../components/Grid/GridContainer"
import GridItem from "../components/Grid/GridItem"
import Inquiry from "../components/Inquiry"
import CustomList from "../components/CustomList"
import Typography from "@material-ui/core/Typography"
import Table from "../components/Table/Table"
import Likes from "../components/Likes"
import TimeAgo from "react-timeago"
// Material Icons
import CallIcon from "@material-ui/icons/Call"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import BusinessIcon from "@material-ui/icons/Business"
import ContactsIcon from "@material-ui/icons/Contacts"
import LinkIcon from "@material-ui/icons/Link"

const heroImageTransformations = "q_auto,f_auto,dpr_2.0,h_180"

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
    },
    gridList: {
      height: 380,
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
      margin: "20px -2px -2px !important",
      paddingTop: "8px",
    },
    mainContainer: {
      backgroundColor: theme.palette.background.paper,
    },
    comments: {
      borderRadius: "4px",
      marginTop: "10px",
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    contact: {
      // paddingTop: "5px !important",
      marginBottom: "8px",
    },
    contactContainer: {
      height: "50px",
    },
    contactText: {
      lineHeight: "50px",
      verticalAlign: "middle",
      marginRight: "6px",
    },
  })
)

function ListingDirectory({ data, ...props }) {
  const classes = useStyles()
  const isMobile = useMediaQuery("(max-width:768px)")
  const isExtraMedium = useMediaQuery("(max-width:1580px)")
  const {
    pageContext: {
      pageData: {
        avatar,
        created,
        body,
        title,
        drupalId,
        type,
        fieldImage,
        fieldLike,
        relationships,
        drupalInternalNid,
      },
    },
  } = props

  const posts = data.allNodeCommunity.edges
  const listings = data.allNodeListing.edges
  const directoryDetails = data.allNodeDirectory.edges[0].node
  const {
    field_address,
    field_contact,
    field_phone_number,
    field_services,
    field_products,
    field_url,
    field_geocode,
    field_hours_of_operation,
  } = directoryDetails

  const MapLink = (props) => {
    const { address } = props
    const gMap = "https://www.google.com/maps/place/"
    const link = `<a href="${
      gMap + address.replace(" ", "+")
    }" target="_blank">${address}</a>`
    return (
      <span
        className={classes.contactText}
        dangerouslySetInnerHTML={{ __html: link }}
      />
    )
  }

  const CustomHeader = `h${isMobile ? 3 : 1}`

  const CustomTitle = () => {
    const style = isMobile
      ? { padding: "0 0 20px 0", textAlign: "center", fontWeight: "bold" }
      : { paddingTop: "16px" }
    return <CustomHeader style={style}>{title}</CustomHeader>
  }

  const HeroSlider = () => {
    return (
      <div className={classes.root}>
        <GridList className={classes.gridList} cols={4}>
          {fieldImage.map((tile) => (
            <GridListTile
              style={{ height: "100%", width: "auto" }}
              key={tile.pid}
            >
              <CloudinaryMediaSingle
                image={tile}
                transformations={heroImageTransformations}
                alt={title}
                progressive={heroImageTransformations}
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
    )
  }

  const SubHeading = () => {
    const image = avatar === "undefined" ? "defaults/default_avatar" : avatar
    const fieldImage = { pid: image, resourceType: "image" }
    return (
      <GridContainer
        spacing={5}
        direction="row"
        alignContent="space-around"
        alignItems="center"
        justify={isMobile ? "center" : "flex-start"}
      >
        <GridItem xs={3} sm={3} md={2}>
          <CloudinaryMediaSingle
            image={fieldImage}
            transformations={"q_auto,f_auto,dpr_auto,w_160,c_scale"}
            alt={relationships.uid.name}
            styles={{ width: "auto", height: "50px" }}
          />
        </GridItem>
        {isMobile && (
          <GridItem style={{ textAlign: "center" }} xs={4} sm={3} md={3}>
            <TimeAgo date={created} />
          </GridItem>
        )}
        <GridItem xs={3} sm={2} md={2}>
          <Likes
            id={drupalInternalNid}
            uuid={drupalId}
            type={type}
            field_like={fieldLike}
            clear={!isMobile}
            getLiveLikes={true}
            formStyle={{ marginBottom: "0" }}
          />
        </GridItem>
      </GridContainer>
    )
  }

  const ContactInfo = () => {
    const Text = ({ text }) => (
      <span className={classes.contactText}>{text}</span>
    )
    return (
      <GridContainer
        spacing={5}
        direction="row"
        alignContent="center"
        alignItems="center"
        justify={isMobile ? "center" : "flex-start"}
        className={classes.contact}
      >
        {field_contact && (
          <GridItem xs={12} md={6} lg={4} className={classes.contactContainer}>
            <ContactsIcon className={classes.contactText} />
            <Text text={field_contact} />
          </GridItem>
        )}
        <GridItem xs={12} md={6} lg={4} className={classes.contactContainer}>
          <CallIcon className={classes.contactText} />
          <a href={`tel:${field_phone_number}`}>
            <Text text={field_phone_number} />
          </a>
        </GridItem>

        <GridItem xs={12} md={6} lg={4} className={classes.contactContainer}>
          <MailOutlineIcon className={classes.contactText} />
          <Inquiry
            title={title}
            id={drupalId}
            linkStyles={{ lineHeight: "50px" }}
            linkText={"Message Us"}
            modalTitle={`${title} Inquiry Form`}
            type={"text"}
            messagePlaceholder={"Enter your message..."}
          />
        </GridItem>

        <GridItem xs={12} className={classes.contactContainer}>
          <LinkIcon className={classes.contactText} />
          <a href={`${urlToSecure(field_url, true)}`} target={"_blank"}>
            <Text text={field_url} />
          </a>
        </GridItem>

        <GridItem xs={12} className={classes.contactContainer}>
          <BusinessIcon className={classes.contactText} />
          <MapLink address={capitalize(field_address)} />
        </GridItem>
      </GridContainer>
    )
  }

  const Hours = () => {
    const hours = JSON.parse(field_hours_of_operation).map((item) => {
      const show = item.status === "open"
      const status =
        item.status === "always" ? "24 Hours" : capitalize(item.status)
      return [item.day, status, show && item.open, show && item.close]
    })
    return <Table tableData={hours} />
  }

  return (
    <Layout disableGutters maxWidth={"xl"} {...props}>
      <GridContainer
        className={classes.mainContainer}
        id={"mainContainer"}
        spacing={5}
        direction="row"
        justify="center"
        alignContent="center"
      >
        <HeroSlider />
        <GridItem xs={12} md={11} lg={isExtraMedium ? 10 : 8} xl={8}>
          <GridContainer
            id={"mainContainer"}
            spacing={3}
            direction="row"
            justify="center"
            alignContent="center"
          >
            {/* Main */}
            <GridItem xs={12} md={9}>
              <GridContainer>
                <GridItem xs={!isMobile ? 9 : 12}>
                  <CustomTitle />
                </GridItem>
                {!isMobile && (
                  <GridItem style={{ paddingTop: "60px" }} xs={3}>
                    <TimeAgo style={{ float: "right" }} date={created} />
                  </GridItem>
                )}
                <GridItem xs={12}>
                  <SubHeading />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: "20px" }}>
                  <Typography variant="h6" className={classes.title}>
                    Contact Information
                  </Typography>
                  <ContactInfo />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: "20px" }}>
                  <Typography variant="h6" className={classes.title}>
                    Business Description
                  </Typography>
                  <p dangerouslySetInnerHTML={{ __html: body }} />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: "20px" }}>
                  <GridContainer>
                    {field_products.length > 0 && (
                      <GridItem xs={12} md={6}>
                        <CustomList title="Products" items={field_products} />
                      </GridItem>
                    )}
                    {field_services.length > 0 && (
                      <GridItem xs={12} md={6}>
                        <CustomList title="Services" items={field_services} />
                      </GridItem>
                    )}
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} md={6} style={{ marginTop: "20px" }}>
                  <Typography variant="h6" className={classes.title}>
                    Business Location
                  </Typography>
                  <GoogleMap
                    name={title}
                    address={JSON.parse(field_geocode)}
                    height={"300px"}
                  />
                </GridItem>
                <GridItem xs={12} md={6} style={{ marginTop: "20px" }}>
                  <Typography variant="h6" className={classes.title}>
                    Hours of Operation
                  </Typography>
                  <Hours />
                </GridItem>
                {listings.length > 0 && (
                  <GridItem xs={12} style={{ marginTop: "20px" }}>
                    <Typography variant="h6" className={classes.title}>
                      Active Listings
                    </Typography>
                    <RelatedItems items={listings} />
                  </GridItem>
                )}
                {posts.length > 0 && (
                  <GridItem xs={12} style={{ marginTop: "20px" }}>
                    <Typography variant="h6" className={classes.title}>
                      Community Posts
                    </Typography>
                    <RelatedItems items={posts} />
                  </GridItem>
                )}
              </GridContainer>
              {/* End Main */}
              {/* Comments */}
              <GridItem xs={12} className={classes.comments}>
                <Comments id={drupalId} type={type} />
              </GridItem>
            </GridItem>
            {/* End Comments */}
            {/* Sidebar */}
            {/* End Sidebar */}
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Layout>
  )
}

export const query = graphql`
  query($alias: String!, $author: String!) {
    allNodeDirectory(filter: { path: { alias: { eq: $alias } } }) {
      edges {
        node {
          field_address
          field_contact
          field_email
          field_phone_number
          field_services
          field_products
          field_url
          field_geocode
          field_hours_of_operation
        }
      }
    }
    allNodeCommunity(
      filter: { relationships: { uid: { id: { eq: $author } } } }
    ) {
      edges {
        node {
          title
          field_cloudinary
          created
          body {
            summary
          }
          path {
            alias
          }
        }
      }
    }
    allNodeListing(
      filter: { relationships: { uid: { id: { eq: $author } } } }
    ) {
      edges {
        node {
          title
          field_cloudinary
          created
          body {
            summary
          }
          path {
            alias
          }
        }
      }
    }
  }
`

export default ListingDirectory

// const video = () => {
//   if (field_youtube_link) {
//     const url = getYoutubeEmbed(field_youtube_link)
//     if (url) {
//       return (
//         <div className={"embed-responsive embed-responsive-16by9"}>
//           <Iframe
//             className={"embed-responsive-item"}
//             url={url}
//             id={field_youtube_link}
//             allowFullScreen
//           />
//         </div>
//       )
//     }
//   }
//   return ""
// }
