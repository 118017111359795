import React, { useState } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import useMediaQuery from "@material-ui/core/useMediaQuery"
// @material-ui/icons
import Close from "@material-ui/icons/Close"
// core components
import Button from "../CustomButtons/Button"
// Styling
import modalStyle from "../../assets/jss/material-kit-pro-react/modalStyle"
import useTheme from "@material-ui/core/styles/useTheme"
import InquiryForm from "./InquiryForm"
import PropTypes from "prop-types"

const style = (theme) => ({
  ...modalStyle(theme),
  modalRootLocal: {
    "& > div:first-child": {
      display: "none",
    },
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
})

const useStyles = makeStyles(style)

const Inquiry = (props) => {
  const { title, id, type, linkText, buttonText, modalTitle, messagePlaceholder, linkStyles } = props
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(null)

  const successMessage =
    "<h2>Congratulations! Your inquiry message has been sent. The seller will be with you shortly!!</h2>" +
    "<p>This message will close automatically in five seconds</p>"

  const success = () => {
    setMessage(successMessage)
    setTimeout(() => {
      setOpen(false)
      setMessage(null)
    }, 5000)
  }

  return (
    <div style={{ display: type === "button" ? "unset" : "inline-block" }}>
      <div onClick={() => setOpen(true)}>
        {type === "button" ? (
          <Button color="danger">
            {buttonText}
          </Button>
        ) : (
          <a style={linkStyles ? linkStyles : []} href="#" onClick={(e) => e.preventDefault()}>
            {linkText}
          </a>
        )}
      </div>
      {open && (
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth={"sm"}
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => {
                setOpen(false)
                setMessage(null)
              }}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>{modalTitle}</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            {message ? (
              <p dangerouslySetInnerHTML={{ __html: message }} />
            ) : (
              <InquiryForm title={title} id={id} success={success} messagePlaceholder={messagePlaceholder} />
            )}
          </DialogContent>
          <DialogActions className={classes.modalFooter} />
        </Dialog>
      )}
    </div>
  )
}

Inquiry.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  modalTitle: PropTypes.string,
  buttonText: PropTypes.string,
  linkText: PropTypes.string,
  type: PropTypes.string,
  messagePlaceholder: PropTypes.string,
}

Inquiry.defaultProps = {
  title: "Sandy",
  modalTitle: "Vessel Inquiry Form",
  buttonText: "Contact Seller",
  linkText: "Contact Seller for Pricing",
  type: "button",
  messagePlaceholder: "Specific questions not in the listing, schedule a sea trial, etc.",
}

export default Inquiry
