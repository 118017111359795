import React, { useState, useEffect } from "react"
import CustomInput from "../CustomInput/CustomInput"
import Button from "../CustomButtons/Button"
import Recaptcha from "react-google-recaptcha"
import { Input } from "@material-ui/core"
import { RefreshContent } from "react-js-pull-to-refresh"
import { isAuthenticated, getEmail } from "../../utils/Auth"
// import { navigate } from "gatsby-link"
import javascriptStyles from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx"
import withStyles from "@material-ui/core/styles/withStyles"

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY

const InquiryForm = props => {
  const { classes, success, title, id, messagePlaceholder } = props
  const [state, setState] = useState({['email']: ''})
  const [disabled, setDisabled] = useState(false)
  const recaptchaRef = React.createRef()

  useEffect(() => {
    isAuthenticated() && setState({...state, ['email']: getEmail()})
  }, [true])

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSubmit = e => {
    e.preventDefault()
    setDisabled(true)
    const recaptchaValue = recaptchaRef.current.getValue()
    fetch("/.netlify/functions/mandrill", {
      method: "POST",
      body: JSON.stringify({
        id: id,
        recaptchaToken: recaptchaValue,
        subject: `CCO Inquiry: ${title}`,
        ...state,
      }),
    })
      .then(response => {
        // console.log(response)
        success()
        // navigate(form.getAttribute("action"))
      })
      .catch(error => console.log(error))
  }

  return (
    <form onSubmit={handleSubmit}>
      <CustomInput
        labelText="Name"
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses,
          required: true,
        }}
        inputProps={{
          onChange: handleChange,
          type: "text",
          className: "form-control",
          name: "name",
          placeholder: "Name...",
          autoFocus: false,
        }}
      />
      <CustomInput
        labelText="Email"
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses,
          required: true,
        }}
        inputProps={{
          onChange: handleChange,
          name: "email",
          type: "text",
          className: "form-control",
          placeholder: "Email...",
          autoFocus: false,
          value: state.email
        }}
      />
      <CustomInput
        labelText="Phone Number"
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses,
          required: true,
        }}
        inputProps={{
          onChange: handleChange,
          name: "phone",
          type: "tel",
          className: "form-control",
          placeholder: "Phone Number...",
          autoFocus: false,
        }}
      />
      <Input
        label="Message"
        type="text"
        name="message"
        onChange={handleChange}
        placeholder={messagePlaceholder}
        style={{ width: "100%" }}
        required={true}
        inputProps={{ maxLength: 1000 }}
        multiline={true}
        rows={10}
      />
      <Recaptcha
        ref={recaptchaRef}
        sitekey={RECAPTCHA_KEY}
        style={{ textAlign: "-webkit-center", margin: "20px" }}
      />
      {disabled && <RefreshContent />}
      <Button
        disabled={disabled}
        type="submit"
        color="primary"
        style={{ float: "right" }}
      >
        Send Inquiry
      </Button>
    </form>
  )
}

export default withStyles(javascriptStyles)(InquiryForm)
