import React from "react"
import GoogleMapReact from "google-map-react"
import { isBrowser } from "../../utils/Auth"

const GoogleMap = (props) => {
  if (!isBrowser) return null

  const style = {
    color: 'white',
    background: 'grey',
    padding: '15px 10px',
    display: 'inline-flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    transform: 'translate(-50%, -50%)'
  }

  const Text = ({ text }) => <div style={style}>{text}</div>

  const { address, name, height } = props
  const googleMapsApiKey = process.env.GATSBY_GOOGLE_MAPS_API_KEY
  const lat = parseFloat(address.lat)
  const lng = parseFloat(address.lng)
  return (
    <section style={{ width: "100%", height: `${height}` }}>
      <div style={{ width: "100%", height: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleMapsApiKey }}
          defaultCenter={[lat, lng]}
          defaultZoom={11}
        >
          <Text lat={lat} lng={lng} text={name} />
        </GoogleMapReact>
      </div>
    </section>
  )
}

export default GoogleMap
