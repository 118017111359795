import React from "react"
import PropTypes from "prop-types"
// Material UI Kit Components
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"
import { CloudinaryMediaSingle } from "../CloudinaryMedia"
import TimeAgo from "react-timeago"
import { Link } from "gatsby"

const RelatedItems = (props) => {
  const { items } = props
  const isMobile = useMediaQuery("(max-width:768px)")

  return (
    <GridContainer spacing={5} direction="row">
      <GridItem xs={12}>
        {items
          .sort(function (a, b) {
            let dateA = new Date(a.node.created),
              dateB = new Date(b.node.created)
            return dateA - dateB
          })
          .reverse()
          .slice(0, 4)
          .map((item, idx) => {
            const {
              title,
              field_cloudinary,
              created,
              body: { summary },
              path: { alias },
            } = item.node
            return (
              <Link to={alias} key={idx}>
                <GridContainer
                  spacing={5}
                  direction="row"
                  alignContent="center"
                  alignItems="center"
                  justify={isMobile ? "center" : "flex-start"}
                  style={{
                    backgroundColor: idx % 2 ? "#EEEEEE" : "inherit",
                    borderRadius: "4px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <GridItem xs={3} md={2}>
                    <CloudinaryMediaSingle
                      image={JSON.parse(field_cloudinary)[0]}
                      transformations={
                        "q_auto,f_auto,dpr_auto,w_180,h_180,c_fill"
                      }
                      alt={title}
                      styles={{ width: "auto", borderRadius: "4px" }}
                    />
                  </GridItem>

                  <GridItem xs={9} md={10}>
                    <GridContainer key={idx} spacing={5} direction="row">
                      <GridItem xs={12}>
                        <h3>{title}</h3>
                        <span>{summary}</span>
                        <TimeAgo style={{ float: "right" }} date={created} />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </Link>
            )
          })}
      </GridItem>
    </GridContainer>
  )
}

RelatedItems.propTypes = {
  items: PropTypes.array.isRequired,
}

export default RelatedItems
