import React from "react"
import PropTypes from "prop-types"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import LabelImportantIcon from "@material-ui/icons/LabelImportant"

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      // maxWidth: 752,
    },
    list: {
      backgroundColor: "#eeeeee",
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
  })
)

const CustomList = (props) => {
  const { title, items } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <div className={classes.list}>
            <List>
              {items.map((item, idx) => (
                <ListItem key={idx}>
                  <ListItemIcon>
                    <LabelImportantIcon color={"error"} />
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

CustomList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
}
export default CustomList
